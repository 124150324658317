import React from "react"
import { Link } from "gatsby"
import { AuthUserContext } from '../components/Session';
import SignOutButton from '../components/SignOut';
import * as ROUTES from '../constants/routes';
import * as ROLES from '../constants/roles';

import Layout from "../components/layout"
import SEO from "../components/seo"
import Navlinks from '../components/nav';
import Helmet from "react-helmet";


const IndexUser = ({ authUser }) => (
  <React.Fragment>
    <span className="icon-avatar"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
    <h1>Welcome {authUser.username}</h1>
  </React.Fragment>
);

const IndexNonUser = () => (
  <React.Fragment>
    <span className="icon-avatar"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
    <h1>Welcome Guest</h1>
  </React.Fragment>
);

const IndexUserPort = ({ authUser }) => (
  <React.Fragment>
    <Link className="portfolioLink" to={ROUTES.PORTFOLIOS}>My Portfolios <small>(revised)</small></Link>
    <Link className="portfolioLink" to="/my-portfolios">My Portfolios <small>(orginal)</small></Link>
    <SignOutButton />
  </React.Fragment>
);

const IndexNoUserPort = () => (
  <React.Fragment>
    <Link className="portfolioLink" to="/my-portfolios">My Portfolios <small>(orginal)</small></Link>
  </React.Fragment>
);

const LandingPage = () => (
  <Layout>
    <SEO title="Compass Minerals SaltSmart" />
      <Helmet defer={true}>
        <body className="home" />
      </Helmet>
      <header className="mainHead flexContainer homeHead  faCenter fjCenter">      
        <div className="fCol12 menuCtrl">
          <div className="headerTitle">Home</div>
          <Navlinks />
        </div>
      </header>
      <section id="home-content" className="flexContainer secondaryTab">
        <div className="fCol12 maxTablet">
          <div className="flexContainer homeInner">
            <AuthUserContext.Consumer>
                {authUser =>
                  authUser ? (
                    <IndexUser authUser={authUser} />                   
                  ) : (
                    <IndexNonUser />
                  )
                }
            </AuthUserContext.Consumer>
            <Link to="/us">U.S. Products</Link>
            <Link className="disabled" to="#">Canada Products</Link>
            <Link to="/company-information">Company Information</Link>
            <AuthUserContext.Consumer>
                {authUser =>
                  authUser ? (
                    <IndexUserPort authUser={authUser} />                   
                  ) : (
                    <IndexNoUserPort />
                  )
                }
            </AuthUserContext.Consumer>
          </div>
          <div className="compassLogo"/>
        </div>
      </section>    
  </Layout>
);


export default LandingPage

